import BaseBridge from 'config/bridge/BaseBridge'
import BaseService from 'config/bridge/BaseService'
import { BridgeMockUserInfo } from 'config/bridge/BridgeMock'
import { IProcessingTicketsResponse } from 'store/tickets/types'

export default class CheckoutService {
  static async checkoutProcessing(capitalizationLot: string) {
    let headers = {}

    if (BaseBridge.isBrowser()) {
      headers = {
        'x-inter-user-bank-account': BridgeMockUserInfo.account,
      }
    }

    const response = await BaseService.doExecute<IProcessingTicketsResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_CAPITALIZATION}/sales/receipt?capitalizationLot=${capitalizationLot}`,
      headers,
    })

    return response.data
  }
}
