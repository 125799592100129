import { useCallback, useEffect, useState } from 'react'

import { ActionIconEnum } from 'common/enums/ActionIcon'
import { PageTitles } from 'common/enums/PageTitles'
import { TagEventsEnum } from 'common/enums/TagEventsEnum'
import { ListDescription, Separator } from 'components'
import { CustomAccordion } from 'components/CustomAccordion/CustomAccordion'
import BaseBridge from 'config/bridge/BaseBridge'
import { useNavBar } from 'hooks/useNavBar'
import { useHistory } from 'react-router-dom'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'
import { ITicketDetails } from 'store/ticketDetails/types'
import { convertCurrentDateTimeToString } from 'utils/commons'
import { Icons } from 'utils/icons'

import { Alert } from '@interco/inter-ui/components/Alert'
import { Flex } from '@interco/inter-ui/components/Flex'
import { Tag } from '@interco/inter-ui/components/Tag'
import { Text } from '@interco/inter-ui/components/Text'
import { formatToBRL } from '@interco/lib-util'

import { RafflesCarousel } from '../../../components/RafflesCarousel/RafflesCarousel'
import * as S from './AfterSalesDetails.styles'
import {
  AnalyticsTicketDetailsAccordion,
  AnalyticsTicketDetailsActionButtons,
  AnalyticsTicketDetailsHeader,
} from './AfterSalesDetails.tags'

interface IDetails {
  title: string
  value: string | number | JSX.Element
}

enum ActionEnum {
  GENERAL_CONDITIONS,
  SUBSCRIPTION_FORM,
  CERTIFICATE,
}

export const AfterSalesDetails = () => {
  const history = useHistory()

  const isLoading = useBoundState((state) => state.isLoading)
  const ticketInfo = useBoundState((state) => state.currentTicketDetails)
  const getUrlTitle = useBoundState((state) => state.getUrlTitle)

  const [expanded, setExpanded] = useState(false)
  const chevronRight = <Icons.ChevronRight color="var(--primary500)" width={24} height={24} />

  const handleBack = useCallback(() => {
    setTimeout(() => history.push(TypesRoutes.AFTER_SALES), 50)
  }, [history])

  useNavBar({
    navbarTitle: PageTitles.SCRATCHCARD,
    actionIcons: {
      leftIcon: ActionIconEnum.BACK,
      rightIcon: ActionIconEnum.FAQ,
    },
    backListener: handleBack,
    backButtonCallback: () => AnalyticsTicketDetailsHeader('32', 'back'),
    helpButtonCallback: () => AnalyticsTicketDetailsHeader('33', 'ajuda'),
  })

  useEffect(() => {
    BaseBridge.tacWb({
      name: 'after_sales_details_screen_loaded',
      module: 'capitalizacao',
      actions: ['enter_after_sales_details'],
      params: {},
    })

    BaseBridge.requestAnalytics(TagEventsEnum.CAP_FRONT, {
      screen: 'raspadinha detalhes',
      cashback: JSON.stringify(ticketInfo.cashback),
      instantPrize: JSON.stringify(ticketInfo.instantPrize),
    })
  }, [])

  const renderListItem = (title: string, actionEnum: ActionEnum) => {
    interface IDictFunction {
      [key: string]: () => void
    }
    const actionDict: IDictFunction = {}

    actionDict[ActionEnum.GENERAL_CONDITIONS] = () => {
      AnalyticsTicketDetailsActionButtons('39', 'Condições gerais')
      BaseBridge.openPdf(
        `https://static.bancointer.com.br/seguros/capitalization/condicoes-gerais/${ticketInfo.susepProcess}/CondicoesGerais_Sortezinha.pdf`,
        'Condições Gerais',
      )
    }

    actionDict[ActionEnum.SUBSCRIPTION_FORM] = () => {
      AnalyticsTicketDetailsActionButtons('40', 'Ficha de cadastro')
      history.push(TypesRoutes.REGISTRATION_FORM)
    }

    actionDict[ActionEnum.CERTIFICATE] = () => {
      AnalyticsTicketDetailsActionButtons('41', 'Titulo')
      getUrlTitle(ticketInfo.ticketId)
    }

    return (
      <ListDescription
        borderBottom
        margin="0 0 16px 0"
        iconRight={chevronRight}
        onClick={actionDict[actionEnum]}
        isLoading={isLoading}
      >
        <Flex direction="column">
          <Text variant="body-2" colorWeight={isLoading ? 300 : 500} bold>
            {title}
          </Text>
        </Flex>
      </ListDescription>
    )
  }

  const FlexRow = ({ title, value }: IDetails) => (
    <Flex direction="row">
      <Text variant="body-3">{title}</Text>
      <Text variant="body-3" bold colorWeight={500}>
        {value}
      </Text>
    </Flex>
  )

  const renderFlexRows = () => {
    interface IDictStr {
      [key: string]: string
    }

    const statusDict: IDictStr = {}
    statusDict.AVAILABLE = 'Disponível'
    statusDict.ACTIVE = 'Ativo'
    statusDict.COMPLETED = 'Finalizado'

    const status = statusDict[ticketInfo.status]

    const instantResult = ticketInfo.ticketAwarded ? (
      <S.StatusTag>
        <Tag theme="green">Você ganhou</Tag>
      </S.StatusTag>
    ) : (
      'Não foi dessa vez'
    )

    return (
      <>
        <FlexRow title="Status" value={status} />
        <FlexRow title="Número da sorte" value={ticketInfo.luckyNumber} />
        <FlexRow title="Resultado instantâneo" value={instantResult} />

        {ticketInfo.ticketAwarded && ticketInfo.instantPrize && (
          <>
            {ticketInfo.instantPrize.paymentDate && (
              <FlexRow
                title="Descrição do prêmio instantâneo"
                value={convertCurrentDateTimeToString(ticketInfo.instantPrize.paymentDate)}
              />
            )}

            {ticketInfo.instantPrize.prizeAmount && (
              <FlexRow
                title="Valor do prêmio instantâneo"
                value={formatToBRL(ticketInfo.instantPrize.prizeAmount)}
              />
            )}
          </>
        )}

        <Separator borderWidth="1px" type="dashed" />
        <FlexRow title="Número do título" value={ticketInfo.titleNumber} />
        <FlexRow
          title="Data da compra"
          value={convertCurrentDateTimeToString(ticketInfo.purchaseDate)}
        />
        <FlexRow
          title="Fim da vigência"
          value={convertCurrentDateTimeToString(ticketInfo.validityEnd)}
        />
        <FlexRow title="Valor da compra" value={formatToBRL(ticketInfo.purchaseValue)} />

        {ticketInfo.cashback && (
          <>
            {ticketInfo.cashback.paymentDate ? (
              <>
                <Separator borderWidth="1px" type="dashed" />
                <FlexRow
                  title="Data do depósito"
                  value={convertCurrentDateTimeToString(ticketInfo.cashback.paymentDate)}
                />
                <FlexRow title="Valor do cashback" value={formatToBRL(ticketInfo.cashback.value)} />
                <S.AlertWrapper>
                  <Alert type="success">Seu cashback já foi depositado!</Alert>
                </S.AlertWrapper>
              </>
            ) : (
              <S.AlertWrapper>
                <Alert type="warning">
                  O resgate do seu cashback de 50% será depositado na sua conta no fim da vigência.
                </Alert>
              </S.AlertWrapper>
            )}
          </>
        )}

        {ticketInfo.warningMessage && (
          <S.AlertWrapper>
            <Alert type="warning">{ticketInfo.warningMessage}</Alert>
          </S.AlertWrapper>
        )}
      </>
    )
  }

  const toggleCustomAccordion = (info: ITicketDetails) => {
    const result = info.ticketAwarded ? 'Você ganhou' : 'Não foi dessa vez'
    AnalyticsTicketDetailsAccordion(info.status, result)
    setExpanded(!expanded)
  }

  return (
    <S.Container>
      <S.AccordionSection data-testid="accordion-section">
        <CustomAccordion
          labelComponent={
            <S.Header data-testid="test-accordion" $expanded={expanded}>
              <Text variant="body-2" colorWeight={500} bold>
                Sortezinha
              </Text>
              <Icons.ChevronDown width={24} height={24} color="var(--primary500)" />
            </S.Header>
          }
          expanded={expanded}
          maxHeightWhenClosed={ticketInfo.ticketAwarded ? '124px' : '80px'}
          onClick={() => toggleCustomAccordion(ticketInfo)}
        >
          <Flex direction="column" gap="16px">
            {renderFlexRows()}
          </Flex>
        </CustomAccordion>
      </S.AccordionSection>

      <S.Section data-testid="raffles-section">
        <Text variant="body-2" colorWeight={500} bold>
          Sorteios
        </Text>
        {ticketInfo.raffles && (
          <RafflesCarousel luckyNumber={ticketInfo.luckyNumber} raffles={ticketInfo.raffles} />
        )}
      </S.Section>

      <Separator color="var(--gray100)" margin="32px 0" borderWidth="8px" />

      <S.Section>
        {renderListItem('Condições Gerais', ActionEnum.GENERAL_CONDITIONS)}
        {renderListItem('Ficha de Cadastro', ActionEnum.SUBSCRIPTION_FORM)}
        {renderListItem('Título', ActionEnum.CERTIFICATE)}
      </S.Section>
    </S.Container>
  )
}
