import BaseBridge from 'config/bridge/BaseBridge'
import BaseService from 'config/bridge/BaseService'
import { BridgeMockUserInfo } from 'config/bridge/BridgeMock'
import {
  IAvailableTicketsResponse,
  ICompleteTicketResponse,
  IProcessingTicketsResponse,
  IScratchRequest,
} from 'store/tickets/types'

export default class TicketService {
  static async processingTickets() {
    let headers = {}

    if (BaseBridge.isBrowser()) {
      headers = {
        'x-inter-user-bank-account': BridgeMockUserInfo.account,
      }
    }

    const response = await BaseService.doExecute<IProcessingTicketsResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_CAPITALIZATION}/tickets/processing`,
      headers,
    })

    return response.data
  }

  static async availableTickets() {
    let headers = {}

    if (BaseBridge.isBrowser()) {
      headers = {
        'x-inter-user-bank-account': BridgeMockUserInfo.account,
      }
    }

    const response = await BaseService.doExecute<IAvailableTicketsResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_CAPITALIZATION}/tickets/available`,
      headers,
    })

    return response.data
  }

  static async scratchTicket({ ticketId, cardId }: IScratchRequest) {
    let headers = {}

    if (BaseBridge.isBrowser()) {
      headers = {
        'x-inter-user-bank-account': BridgeMockUserInfo.account,
      }
    }

    const response = await BaseService.doExecute({
      method: 'PUT',
      endpoint: `${process.env.REACT_APP_API_CAPITALIZATION}/tickets/scratch?ticketId=${ticketId}&cardId=${cardId}`,
      headers,
    })

    return response.data
  }

  static async completeTicket(ticketId: string) {
    let headers = {}

    if (BaseBridge.isBrowser()) {
      headers = {
        'x-inter-user-bank-account': BridgeMockUserInfo.account,
        'x-inter-user-social-id': (await BaseBridge.getUserInfo()).cpf,
      }
    }

    const response = await BaseService.doExecute<ICompleteTicketResponse>({
      method: 'PUT',
      endpoint: `${process.env.REACT_APP_API_CAPITALIZATION}/tickets/complete?ticketId=${ticketId}`,
      headers,
    })

    return response.data
  }
}
